<!--System: Whistle it 
  Module: Super Admin
  Developer: Gulraiz Ahmed 
  Date: 17-06-2021 
  Organization: Whistle it 
  Purpose: The purpose of this component is to display Channel details  -->

<template>
  <div>
    <v-sheet
      color="white"
      :height="$vuetify.breakpoint.xs ? '130' : '83'"
      width="100%"
      :class="$vuetify.breakpoint.xs ? 'd-flex flex-column' : 'd-flex '"
      class="px-8 py-8"
    >
      <!---------------------------------------------Search Textfield------------------------------------------------>
      <v-text-field
        v-model="search"
        class="mt-n3"
        style="max-width: 200px"
        prepend-inner-icon="mdi-magnify"
        label="Search"
        placeholder="Search Channels"
        single-line
        outlined
        dense
        autofocus
        color="admin-primary"
        hide-details
      >
      </v-text-field>
      <v-spacer></v-spacer>

      <v-select
        class="mt-n3"
        style="max-width: 200px"
        v-model="select"
        :items="companiesList"
        label="Select Company"
        dense
        solo
        outlined
      >
      </v-select>
    </v-sheet>

    <v-skeleton-loader
      v-if="firstLoad"
      :loading="loading"
      type="table-thead,table-tbody"
    ></v-skeleton-loader>
    <!------------------------------------------------Data Table------------------------------------------------>
    <v-data-table
      :headers="headers"
      :items="Channels"
      v-show="!firstLoad"
      class="shadow-class tableRowBg"
      :search="search"
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      :item-class="rowClass"
      :loading="loading"
    >
      <!-------------------------------------------- Dialog to Edit Channels -------------------------------------->
      <template v-slot:top>
        <v-dialog v-model="dialog" persistent max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
              <v-spacer></v-spacer>
              <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <!-----------------------------------------------Name Textfield-------------------------------------------->
                    <v-text-field
                      v-model="editedItem.name"
                      label="Channel Name"
                      outlined
                      color="admin-primary"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <!-----------------------------------------------Type Textfield-------------------------------------------->
                    <v-text-field
                      v-model="editedItem.type"
                      label="type"
                      outlined
                      color="admin-primary"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <!-----------------------------------------------Team-Id Textfield------------------------------------------>
                    <v-text-field
                      v-model="editedItem.teamId"
                      label="team"
                      outlined
                      color="admin-primary"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <!-----------------------------------------------Created-Date Textfield------------------------------------->
                    <v-text-field
                      v-model="editedItem.createdDate"
                      label="Created Date"
                      outlined
                      color="admin-primary"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-----------------------------------------------------Buttons----------------------------------------------->
              <v-btn class="white--text" color="admin-primary" @click="close">
                Cancel
              </v-btn>
              <v-btn
                class="white--text"
                color="admin-primary"
                @click="savechanneldata"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-------------------------------------------Dialogue Box for delete----------------------------------------->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="white--text"
                color="admin-primary"
                @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                class="white--text"
                color="admin-primary"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!------------------------------------ slot for Channel creation Date ------------------------------------->

      <template v-slot:[`item.created_at`]="{ item }">
        <div :class="$vuetify.breakpoint.xs ? 'my-3' : 'my-5'">
          {{ new Date(item.created_at).toLocaleString("en-US", options) }}
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top color="admin-tooltip-color">
          <!----------------------------------------- Edit Channel Name --------------------------------------------->
          <template v-slot:activator="{ on, attrs }">
            <!-----------------------------------------Icons of Edit and Delete---------------------------------------->
            <v-icon
              disabled
              small
              v-bind="attrs"
              v-on="on"
              class="mr-2 admin-primary--text"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit Team</span>
        </v-tooltip>
        <!-------------------------------------------- Delete Channel --------------------------------------------->
        <v-tooltip top color="admin-tooltip-color">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              disabled
              small
              v-bind="attrs"
              v-on="on"
              class="admin-primary--text"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Delete Team</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <!---------------------------------------------Pagination------------------------------------------------>
    <div
      class="d-flex justify-space-between"
      :class="$vuetify.breakpoint.xs ? 'flex-column ' : 'py-4 px-8'"
    >
      <span
        class="d-flex justify-start"
        :class="$vuetify.breakpoint.xs ? 'ml-15 ' : ''"
      >
        <v-subheader class="pb-2"> Rows per Page </v-subheader>
        <v-select
          :items="perPage"
          dense
          color="#432662"
          @change="
            setPerPage($event);
            selected = true;
          "
          class="select-per-page"
          style=""
        >
          <template v-slot:append>
            <v-icon small color="admin-primary" class="py-1"
              >mdi-chevron-down</v-icon
            >
          </template>
          <template v-slot:label>
            <span
              style="font-size: 12px"
              class="pl-3"
              :class="selected ? 'd-none' : ''"
              >{{ itemsPerPage }}</span
            >
          </template>
        </v-select>
      </span>

      <v-pagination
        color="admin-primary"
        id="#pagination"
        v-model="page"
        :length="pageCount"
        size="small"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        total-visible="5"
      ></v-pagination>
    </div>
    <v-snackbar v-model="signoutErrorSnack" right top color="green">
      {{ signoutErrorSnackText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="signoutErrorSnack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<!------------------------------------------------Script-------------------------------------------------->
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "channelsList",

  data() {
    return {
      signoutErrorSnackText: "Your session has expired !!!",
      signoutErrorSnack: false,
      loading: true,
      firstLoad: true,
      select: "",
      //PAGINATION VARIABLES
      page: 1,
      pageCount: 0,
      itemsPerPage: 25,
      pagination: true,
      perPage: [25, 50, 100, 250, 500, 750, 1000],
      //-------------------------------------------------------------------------------------------------------->
      search: "",
      dialog: false,
      dialogDelete: false,
      dialoginfo: false,
      selected: false,
      companiesList: [],

      //options for date formate
      options: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      //-------------------------------------------------Headers------------------------------------------------>
      headers: [
        {
          text: "Channel Name",
          align: "start",
          filterable: true,
          value: "name",
          class: "admin-primary--text tableHeaderBg",
        },
        {
          text: "Type",
          value: "type",
          class: "admin-primary--text tableHeaderBg",
        },
        {
          text: "Team id",
          value: "team_id",
          class: "admin-primary--text tableHeaderBg",
        },
        {
          text: "Created Date",
          value: "created_at",
          class: "admin-primary--text tableHeaderBg",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "admin-primary--text tableHeaderBg",
        },
      ],
      editedIndex: -1,
      editedItem: {
        name: "",
      },
      defaultItem: {
        name: "",
      },
    };
  },
  //-------------------------------------------Computed------------------------------------------------->
  computed: {
    ...mapGetters(["getChannelsData", "getPagesNo", "getCompaniesNames"]),
    formTitle() {
      return this.editedIndex === -1 ? "Edit Item" : "Edit Item";
    },
    Channels: {
      get() {
        return this.getChannelsData;
      },
    },
  },
  //------------------------------------------Wacthers------------------------------------------------->
  watch: {
    //watch property called on selecting company
    async select(val) {
      for (let x of this.getCompaniesNames) {
        if (val == x.name) {
          await this.CompanyChannels(x.id);
          break;
        }
      }
    },
    dialog(val) {
      val || this.close();
    },
    // page(val) {
    //   this.getChannels(val);
    // },
    search() {
      this.pagination = false;
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  //---------------------------------------------methods----------------------------------------------->
  methods: {
    ...mapActions([
      "addchannels",
      "updatechannel",
      "removechannel",
      "getChannels",
      "CompanyChannels",
      "Companies",
    ]),

    async initialize() {
      await this.Companies();
      for (let x of this.getCompaniesNames) {
        this.companiesList.push(x.name);
      }
    },
    editItem(item) {
      this.editedIndex = this.Channels.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.Channels.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.Channels.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    savechanneldata() {
      if (this.editedIndex > -1) {
        Object.assign(this.Channels[this.editedIndex], this.editedItem);
      } else {
        this.Channels.push(this.editedItem);
      }
      this.close();
    },
    getChannelDetails() {},
    setPerPage(perPageEntries) {
      this.itemsPerPage = perPageEntries;
    },
    rowClass() {
      return "admin-table-text";
    },
    loaderFunction: function () {
      setTimeout(() => {
        if (this.firstLoad) this.firstLoad = false;
        this.loading = false;
      }, 2000);
    },
  },
  //--------------------------------------------Mounted------------------------------------------------>
  async mounted() {
    await this.getChannels(this.page).then(
      () => {},
      (error) => {
        if (error.response.status == 401) {
          this.signoutErrorSnack = true;
          let vm = this;
          setTimeout(() => {
            vm.$router.push("/");
          }, 200);
        }
      }
    );
    this.loaderFunction();
    //await this.Channels(this.page);
    this.initialize();
  },
};
</script>
<!------------------------------------------------Style------------------------------------------------->
<style scoped>
.v-tab.v-tab--active {
  background-color: #432662 !important;
  border-radius: 5px;
  color: white !important;
  border-bottom: 2px solid white !important;
  border: none !important;
  text-decoration: none !important;
}
.v-tab {
  background-color: white;
  border-radius: 4px 4px 0 0 !important;
  color: #797593 !important;
  text-decoration: none !important;
  max-height: 40px !important;
  min-width: 112px !important;
}
.search-container {
  background-color: white;
}
.user-table-email-color {
  color: #a899c4;
  font-size: 14px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.v-data-footer {
  display: flex !important;
  justify-content: space-between !important;
}
.v-data-table {
  border-radius: 0px;
}
.table-text-color--text {
  color: #17123b;
  font-size: 14px;

  font-weight: bold;
}
#pagination .v-pagination .v-pagination__item,
.v-pagination .v-pagination__more,
.v-pagination {
  height: 21px !important;
  min-width: 20px !important;
  font-size: 12px !important;
}
#pagination .v-pagination__navigation .v-icon {
  font-size: 16px !important;
}
#pagination .v-pagination__navigation .v-pagination__item {
  font-size: 16px !important;
  height: 20px;
  width: 20px;
  color: #eeeff7;
}
#pagination .v-pagination__navigation {
  height: 21px !important;
  width: 20px !important;
}
.v-pagination__item .v-pagination__item--active {
  height: 12px !important;
}
.v-pagination__navigation {
  height: 12px !important;
  width: 5px !important;
}
.normal-width {
  max-width: 33% !important;
}
.select-per-page {
  box-sizing: border-box;
  height: 27.98px !important;
  max-width: 53px !important;
  border: 1px solid rgba(67, 38, 98, 0.13);
  border-radius: 2px;
  font-size: 12px !important;
  color: rgba(67, 38, 98, 0.13) !important;
}
.tableHeaderBg {
  color: #eeeff7;
}
</style>
