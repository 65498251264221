<!--System: Whistle it 
  Module: Super Admin
  Developer: Gulraiz Ahmed 
  Date: 17-06-2021 
  Organization: Programmers Force 
  Purpose: The purpose of this view is to display Channel details  -->

<template>
<div class="pa-10">
<channels/>
  
</div>
</template>
<script>
import channels from "@/components/channels";
export default {
  components: {
    channels,
  },
};
</script>
